import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [3,4];

export const dictionary = {
		"/(public)": [5,[2]],
		"/(webapp)/admin": [~8,[3]],
		"/(webapp)/admin/menu/[menuFileId]": [~9,[3]],
		"/(webapp)/admin/restaurants": [10,[3]],
		"/(webapp)/dashboard": [11,[3,4]],
		"/login": [~16],
		"/logout": [~17],
		"/(public)/privacy-policy": [6,[2]],
		"/(webapp)/profile": [12,[3]],
		"/(webapp)/profile/[slug]": [13,[3]],
		"/(webapp)/settings": [14,[3]],
		"/(webapp)/settings/billing": [~15,[3]],
		"/(public)/terms": [7,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';